var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legendClass"},_vm._l((_vm.details),function(detail){return _c('div',{key:detail.label,staticClass:"legend-detail"},[(detail.color && detail.label)?_c('div',{style:({
                'background': detail.color, 
                'width': '12px', 
                'height': '10px', 
                'margin-right': '5px', 
                'margin-top': '6px',
                'border-radius': '2px', 
                'border-width': detail.border,
                'border-color': detail.borderColor || '#000',
                'border-style': detail.border?'solid':null
                })}):(detail.text)?_c('div',{staticClass:"text-center text-caption font-weight-black"},[_vm._v(" "+_vm._s(detail.text)+" ")]):_vm._e(),_c('span',{staticClass:"text-uppercase text-caption"},[_vm._v(_vm._s(detail.label))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }