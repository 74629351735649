<template>
  <v-tooltip :top="this.numBinario < 5" :bottom="this.numBinario >= 5" color="#dfe0e1">
    <template v-slot:activator="{ on }">
      <div
        class="wagon"
        :style="`z-index:${zIndex};width:${width}%;left:${position}%;${getWagonBorderStyle()}`"
        v-on:click="wagonOnClick"
        v-on:dblclick="wagonOnDoubleClick"
        v-on:mouseover="mouseover = true"
        v-on:mouseleave="mouseover = false"
        v-on="on"
        :class="{ active: active }"
        @click.stop="toggleActive"
      >
        <div v-if="isCarroConvenzionale(carroConvoglio)" class="wagon-ctnr" stype="f44611" :style="`border: 2px solid #f44611`">
          <div class="conventional-wagon-ctnr" :style="`background:${getCarroConvenzionaleColorImport(carroConvoglio)}`" />
          <div class="conventional-wagon-ctnr" :style="`background:${getCarroConvenzionaleColorExport(carroConvoglio)}`" />
        </div>

        <div v-else class="wagon-ctnr">
          <div class="uties-ctnr">
            <div
              :class="`text-center uti ${!featuresShowing && utiCarro.remainOnBoardScarico ? 'uti-rob' : ''}`"
              v-for="(utiCarro, idx) in orderedImportUties"
              :key="utiCarro.id"
              :title="getUtiCode(utiCarro)"
              :style="calcUtiStyle(carroConvoglio, utiCarro, 'IMPORT', idx)" >
              <span v-if="showTonsText(utiCarro)" class="text-caption font-weight-black">{{ getTonsText() }}</span>
              <span v-if="yardShowing" class="text-caption font-weight-black">{{ getYardText(utiCarro) }}</span>
            </div>
          </div>

          <div class="uties-ctnr">
            <div
              :class="`text-center uti ${!featuresShowing && utiCarro.remainOnBoardScarico ? 'uti-rob' : ''}`"
              v-for="(utiCarro, idx) in orderedExportUties"
              :key="utiCarro.id"
              :title="getUtiCode(utiCarro)"
              :style="calcUtiStyle(carroConvoglio, utiCarro, 'EXPORT', idx)" >
              <span v-if="showTonsText(utiCarro)" class="text-caption font-weight-black">{{ getTonsText() }}</span>
              <span v-if="yardShowing" class="text-caption font-weight-black">{{ getYardText(utiCarro) }}</span>
            </div>
          </div>
        </div>

        <v-sheet
          v-if="featuresShowing"
          :color="getWagonFeatureColor(carroConvoglio, false, featuresShowing)"
          height="5"
          class="wagon-feature mt-1"
        />
        <v-sheet
          v-if="!featuresShowing && viewWagonFeatures"
          :color="getWagonFeatureColor(carroConvoglio, danniCarroConvoglio?.length > 0, featuresShowing)"
          height="5"
          class="wagon-feature mt-1"
        />
      </div>
    </template>
    <div style="width: 450px">
      <wagonInfoPanel :carro="carroConvoglio" :convoglio="convoglioSel" tooltip />
    </div>
  </v-tooltip>
</template>

<script>
import convoyVisualizationMixins from "./mixins/convoyVisualizationMixins";
import wagonInfoPanel from "../misc/WagonInfoPanel.vue";

export default {
  name: "wagon",
  components: {
    wagonInfoPanel,
  },
  props: {
    carroConvoglio: {
      type: Object,
      required: true,
    },
    convoglioSel: {
      type: Object,
      required: false,
    },
    maxLengthFactor: {
      type: Number,
      default: 200,
    },
    utiSearchParam: {
      type: String,
      default: "",
    },
    wagonSearchParam: {
      type: String,
      default: "",
    },
    plannedShowing: {
      type: Boolean,
      default: false,
    },
    featuresShowing: {
      type: Boolean,
      default: false,
    },
    yardShowing: {
      type: Boolean,
      default: false,
    },
    numBinario: {
      type: Number,
    },
    active:{
      type: Boolean,
    }, 
    index:{
      type: Number,
    }
  },
  mixins: [convoyVisualizationMixins],
  data() {
    return {
      zIndex: 99,
      detailOpened: false,
      mouseover: false,
      danniCarroConvoglio: null,
      activeIndex: 0
    };
  },
  async mounted() {
    this.danniCarroConvoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "logDanni/carroConvoglio/" + this.carroConvoglio.id);
  },
  methods: {
    toggleActive() {
      this.$emit("set-active", this.index);
      console.log(this.index);
      console.log(this.active);
      console.log(this.activeIndex);
    },
    wagonOnClick() {
      setTimeout(() => {
        this.$eventBus.$emit("wagonSelected", this.carroConvoglio);
      }, 300);
    },
    wagonOnDoubleClick() {
      this.$eventBus.$emit("convoySelected", this.carroConvoglio.convoglioId);
    },
    calcWidth() {
      return (100 * this.getCarroLength(this.carroConvoglio)) / this.maxLengthFactor;
    },
    calcPosition() {
      return (100 * this.carroConvoglio.posizioneBinario) / this.maxLengthFactor;
    },
    getUtiBorderStyle(utiCarro, idx) {
      const utiCode = this.getUtiCode(utiCarro).toLowerCase();
      let carrierOut = this.getCarrierOut(utiCarro);
      carrierOut = carrierOut ? carrierOut.toLowerCase() : "";
      let serviceOut = this.getServiceOut(utiCarro);
      serviceOut = serviceOut ? serviceOut.toLowerCase() : "";

      const searchPar = this.utiSearchParam.toLowerCase();

      if (
        this.utiSearchParam &&
        ((this.utiSearchParam.length >= 3 &&
          (utiCode.includes(searchPar) ||
            carrierOut.includes(searchPar) ||
            serviceOut.includes(searchPar))) ||
          utiCode === searchPar ||
          carrierOut === searchPar ||
          serviceOut === searchPar)
      ) {
        return {"border": "2px solid #fdf905"};
      } else if (this.featuresShowing && utiCarro?.visitaUti?.fullEmpty === "E") {
        return {"border": "2px solid #000000"};
      } else {
        return this.calcBorder(idx);
      }
    },
    getSpatialInfoError(utiCarro) {
      if (this.wagonSpatials[utiCarro.tipoMovimento.toLowerCase()]?.warn)
        return {
          '-webkit-box-shadow': 'inset 0px 0px 0px 2px #f00',
          '-moz-box-shadow': 'inset 0px 0px 0px 2px #f00',
          'box-shadow': 'inset 0px 0px 0px 2px #f00'
        };

      return {};      
    },
    getWagonBorderStyle() {
      if (this.mouseover) {
        return "border: 2px solid #333333;";
      }
      const licensePlate = this.getCarroLicensePlate(this.carroConvoglio);
      if (
        this.wagonSearchParam &&
        ((this.wagonSearchParam.length >= 3 && licensePlate.includes(this.wagonSearchParam.toLowerCase())) || licensePlate === this.wagonSearchParam.toLowerCase())
      ) {
        return "border: 2px solid #fdf905;";
      }
      return "border-right: 2px dashed #000000;";
    },
    showTonsText(utiCarro) {
      return this.featuresShowing && !this.yardShowing && utiCarro.visitaUti.pesoLordo > 29000;
    },
    viewWagonFeatures() {
      return this.danniCarroConvoglio && this.danniCarroConvoglio.length > 0;
    },
    calcUtiStyle(carroConvoglio, utiCarro, impexp, idx) {
      const style = {
        ...this.calcUtiCompWidth(this.wagonSpatials, utiCarro.id, impexp),
        // ...this.calcUtiCompMargin(this.wagonSpatials, utiCarro.id, impexp),
        ...this.getUtiColorStyle(utiCarro, this.featuresShowing),
        ...this.getUtiBorderStyle(utiCarro, idx),
        ...this.getSpatialInfoError(utiCarro)
      }

      return style; 
    }
  },
  computed: {
    unchargedImportUtiNumber() {
      return this.getUnchargedImportUtiNumber(this.carroConvoglio);
    },
    unchargedExportUtiNumber() {
      return this.getUnchargedExportUtiNumber(this.carroConvoglio);
    },
    chargedImportUtiNumber() {
      return this.getChargedImportUtiNumber(this.carroConvoglio);
    },
    chargedExportUtiNumber() {
      return this.getChargedExportUtiNumber(this.carroConvoglio);
    },
    orderedImportUties() {
      if (!this.plannedShowing && !this.featuresShowing) {
        return this.getRTOrderedImportUties(this.carroConvoglio);
      }
      return this.getOrderedImportUties(this.carroConvoglio);
    },
    orderedExportUties() {
      if (!this.plannedShowing && !this.featuresShowing) {
        return this.getRTOrderedExportUties(this.carroConvoglio);
      }
      return this.getOrderedExportUties(this.carroConvoglio);
    },
    width() {
      return this.calcWidth();
    },
    position() {
      return this.calcPosition();
    },
    licensePlate() {
      return this.getCarroLicensePlate(this.carroConvoglio);
    },
    wagonSpatials() {
      return this.calcWagonSpatials(this.carroConvoglio);
    }
  },
  watch: {
    mouseover: function (newValue) {
      if (newValue) {
        this.$eventBus.$emit("convoyHighlighted", this.carroConvoglio.idConvoglio);
      } else {
        this.$eventBus.$emit("convoyDeHighlighted");
      }
    },
  },
};
</script>

<style scoped>
.wagon {
  position: absolute;
  height: 40px;
  margin-top: 20px;
  border-right: 2px dashed #1b1b0f;
  background: rgba(105, 105, 105, 0.5);
  overflow: visible;
}
.wagon-feature {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 3px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 3px !important;
}
/* .conventional-wagon-ctnr{
    height: 100%;
    width: 100%;
    background: #af9778;
} */
.conventional-wagon-ctnr {
  height: 50%;
  width: 100%;
  float: left;
}
.wagon-ctnr {
  height: 100%;
  width: 100%;
}
.uties-ctnr {
  height: 50%;
  width: 100%;
  float: left;
}
.uti {
  height: 100%;
  float: left;
  border-color: #333333;
  border-style: dashed;
  border-collapse: collapse;
  font-size: 0.3rem;
  overflow: hidden;
}
.uti-number {
  height: 100%;
  width: 50%;
  float: left;
  text-align: center;
  font-size: 0.6rem;
  overflow: hidden;
}
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
.uti i {
  font-size: 0.6rem;
  font-weight: bold;
  float: right;
}
.uti-danger-icon {
  color: red;
}
.uti-text {
  width: 100%;
  height: 100%;
  text-align: center;
}
.uti-text > span {
  margin: 0 auto;
  word-wrap: break-word;
}
.uti-text b {
  word-wrap: break-word;
}

/* .wagon-details{
    height: 20%;
    width: 100%;
    float: left;
    background: #615c59;
    overflow: hidden;
    vertical-align: middle;
}
.wagon-details > span{
    font-size: 0.4rem;
    font-weight: bold;
    line-height: 7px;
    color: #f9fafb;    
    text-align: center;
    display: block;
} */
.active{
  border: 2px solid #333333 !important;
}
</style>
