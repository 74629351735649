<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="2" sm="7">
        <v-combobox v-model="selectedPark" :items="parks" label="Parco selezionato" item-text="codice" />
      </v-col>
      <v-col cols="12" sm="4" offset-sm="1" md="3">
        <v-text-field v-model="wagonSearchParam" append-icon="mdi-magnify" label="Ricerca carro (per targa)" style="max-width: 300px" clearable/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" sm="4" md="3">
        <v-text-field v-model="utiSearchParam" append-icon="mdi-magnify" label="Ricerca UTI (per codice, carrier-out, service-out)" style="max-width: 300px" clearable/>
      </v-col>
      <v-col cols="2" sm="1" md="1" class="text-center">
        <v-btn medium @click="fetchData" :title="tLabel('renew')" color="primary">
          <v-icon>mdi-autorenew</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" sm="4" md="2" class="text-center">
        <v-btn medium @click="onClickPlannedShow" :color="plannedShowing ? 'primary' : null" :title="plannedActivationBtnText" class="no-radius-right">
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
        <v-btn medium @click="onClickFeaturesShow" :color="featuresShowing ? 'primary' : null" :title="featuresActivationBtnText" class="no-radius-left">
          <v-icon>mdi-layers-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" sm="1" md="1">
        <v-btn medium @click="onClickYardShow" :color="yardShowing ? 'primary' : null" :title="yardActivationBtnText">
          <v-icon large>mdi-alpha-y</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!featuresShowing" no-gutters>
      <v-col cols="12">
        <div>
          <span style="float: left; padding-left: 5px">
            <b>ARRIVO:</b>
          </span>
          <calLegend :details="utiWagonStatesExport" />
        </div>
        <div>
          <span style="float: left; padding-left: 20px">
            <b>PARTENZA:</b>
          </span>
          <calLegend :details="utiWagonStatesImport" />
        </div>
        <div>
          <span style="float: left; padding-left: 20px">
            <b>CARRO:</b>
          </span>
          <calLegend :details="wagonLegendVoices" />
        </div>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col cols="12">
        <div>
          <span style="float: left; padding-left: 5px">
            <b>CARRO:</b>
          </span>
          <calLegend :details="wagonFeaturesLegendVoices" />
        </div>
        <div>
          <span style="float: left; padding-left: 20px">
            <b>UTI:</b>
          </span>
          <calLegend :details="utiFeaturesLegendVoices" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <div id="railParkCntr" class="rail-park-cntr">
          <!-- 
            <convoy-dialog :dialog="dialogConvoy" :convoglio="convoglioSel" />
          -->
          <wagon-dialog 
            :dialog="dialog" 
            :carroConvoglio="carroSel" 
            :plannedShowing="plannedShowing" 
            @close="(dialogOpenValue) => (dialog = dialogOpenValue)" />
          <div id="railPark" class="overflow-x-auto bg-purple-lighten-5">
            <railSpatialVisualization
              v-for="binario in binari"
              :key="binario.id"
              :binario="binario"
              :convogli="convogli[binario.id]"
              :maxLengthFactor="maxRailLength"
              :lengthInterval="20"
              :selected="selectedRailId == binario.id"
              :utiSearchParam="utiSearchParam.trim()"
              :wagonSearchParam="wagonSearchParam.trim()"
              :plannedShowing="plannedShowing"
              :featuresShowing="featuresShowing"
              :yardShowing="yardShowing"
              :convoglioSel="convoglioSel"
            />
            <!-- <crane 
              v-for="gru in listaGru" 
              :key="gru.id"
              :gru="gru"
              :maxLengthFactor="maxRailLength"
              :railsNumber="binari.length"
              :railsWagonsMap="railsWagonsMap"
              :selectedRailId="selectedRailId"
            /> -->
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import multiParkManagementMixins from "../../mixins/MultiParkManagementMixins";
import convoyVisualizationMixins from "../../components/rails/mixins/convoyVisualizationMixins";
import railSpatialVisualization from "../../components/rails/RailSpatialVisualization";
import wagonDialog from "../../components/rails/WagonDialog";
import convoyDialog from "../../components/rails/convoy/ConvoyDialog";
//import crane from '../../components/rails/Crane';
import calLegend from "../../components/misc/Legend";

export default {
  name: "realTimeVisualization",
  components: {
    railSpatialVisualization,
    wagonDialog,
    //crane,
    calLegend,
    convoyDialog,
  },
  data() {
    return {
      railZoom: 1,
      binari: [],
      listaGru: [],
      convogli: {},
      resConvogli: [],
      railsWagonsMap: {},
      selectedRailId: -1,
      carroSel: null,
      convoglioSel: null,
      tapEvCache: [],
      tapPrevDiff: -1,
      craneMoving: false,
      dialog: false,
      dialogConvoy: false,
      timerRT: null,
      utiSearchParam: "",
      wagonSearchParam: "",
      plannedShowing: false,
      featuresShowing: false,
      yardShowing: false,
      toggle_exclusive: [],
      wagonLegendVoices: {
        damaged: { label: 'danneggiato', color: '#EF5350' },
        error: { label: 'errori nei dati', color: '#fff', border:'2px', borderColor: '#f00' },
      },
      wagonFeaturesLegendVoices: {
        lowered: { label: 'ribassato', color: '#212121' },
        error: { label: 'errori nei dati', color: '#fff', border:'2px', borderColor: '#f00' }
      },
      utiFeaturesLegendVoices: {
        nofeatures: { label: 'no caratteristiche', color: '#E2E2E2'},
        empty: { label: 'vuoto', color: '#ffffff', border: '2px' },
        reefer: { label: 'reefer', color: '#00E5FF' },
        imo: { label: 'pericoloso', color: '#FFCA28' },
        tons: { label: '> 29tons', text: '29' },
      },

    };
  },
  mixins: [multiParkManagementMixins, convoyVisualizationMixins],
  created() {
    this.$eventBus.$on("wagonSelected", (carro) => {
      this.carroSel = carro;
      this.dialog = true;
    });
    this.$eventBus.$on("wagonUnSelected", () => {
      this.carroSel = null;
      this.dialog = false;
    });
    this.$eventBus.$on("selectingRail", (railId) => (this.selectedRailId = railId));
    this.$eventBus.$on("convoySelected", (idConvoglio) => {
      this.$router.push({ path: `/rails/ConvoyVisualization/${idConvoglio}` });
    });
    this.$eventBus.$on("utiStatusChanged", () => {
      this.$set(this.carroSel, "carroSel");
      this.$set(this.convogli, "convogli");
    });
    this.$eventBus.$on("convoyHighlighted", (idConvoglio) => {
      this.convoglioSel = this._.find(this.resConvogli, (c) => c.id === idConvoglio);
      this.dialogConvoy = true;
    });
    this.$eventBus.$on("convoyDeHighlighted", () => {
      this.dialogConvoy = false;
      this.convoglioSel = null;
    });
  },
  async destroyed() {
    await this.clearDataUpdater();
  },
  async mounted() {
    await this.fetchData();
    this.initComponent();
  },
  computed: {
    maxRailLength() {
      return this.getMaxLengthRail();
    },
    utiWagonStatesExport() {
      return this.$config.rails.utiWagonStates["export"];
    },
    utiWagonStatesImport() {
      return this.$config.rails.utiWagonStates["import"];
    },
    plannedActivationBtnText() {
      return (this.plannedShowing ? "Nascondi" : "Visualizza") + " pianificate/scaricate/da caricare";
    },
    featuresActivationBtnText() {
      return (this.featuresShowing ? "Nascondi" : "Visualizza") + " caratteristiche";
    },
    yardActivationBtnText() {
      return (this.yardShowing ? "Nascondi" : "Visualizza") + " area yard";
    },
  },
  methods: {
    async fetchData() {
      if (!this.parkName) {
        return;
      }

      await this.loadRailwayData();
      //await this.loadCraneData();
      await this.loadRealTimeData();
    },
    async clearDataUpdater() {
      try {
        if (this.timerRT) {
          clearInterval(this.timerRT);
        }
        await this.$wsHubUnRegister("railVisualizationRT");
      } catch (e) {
        console.log(e);
      }
    },
    async renewData() {
      try {
        await this.clearDataUpdater();
        await this.fetchData();
      } catch (e) {
        console.log(e);
      }
    },
    initComponent() {
      const railParkSubCntr = document.querySelector("#railPark");
      let isDown = false;
      let startX, startY, scrollLeft, scrollTop;

      this.$utilities.dom.addHandler(railParkSubCntr, ["wheel"], (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (event.ctrlKey) {
          if (this.$utilities.dom.detectMouseWheelDirection(e) === "up") {
            this.railZoom += this.railZoom < 3 ? 0.1 : 0;
          } else {
            this.railZoom -= this.railZoom > 1 ? 0.1 : 0;
          }
          railParkSubCntr.setAttribute(
            "style",
            `zoom:${this.railZoom};transform: scale(${this.railZoom});transform-origin: 0 0;-webkit-transform-origin: 0 0;-moz-transform:Scale(${this.railZoom});-moz-transform-origin:0 0`
          );
        }
      });

      this.$utilities.dom.addHandler(railParkSubCntr, ["pointerdown"], (e) => {
        this.tapEvCache.push(e);
      });

      this.$utilities.dom.addHandler(railParkSubCntr, ["pointerup"], (e) => {
        for (var i = 0; i < this.tapEvCache.length; i++) {
          if (this.tapEvCache[i].pointerId == e.pointerId) {
            this.tapEvCache.splice(i, 1);
            break;
          }
        }
      });

      this.$utilities.dom.addHandler(railParkSubCntr, ["pointermove"], (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (this.tapEvCache.length == 2) {
          let curDiff = Math.abs(this.tapEvCache[0].clientX - this.tapEvCache[1].clientX);
          if (this.tapPrevDiff > 0) {
            if (curDiff > this.tapPrevDiff) {
              this.railZoom += this.railZoom < 3 ? 0.1 : 0;
            }
            if (curDiff < this.tapPrevDiff) {
              this.railZoom -= this.railZoom > 1 ? 0.1 : 0;
            }
            railParkSubCntr.setAttribute("style", `zoom:${this.railZoom};-moz-transform:Scale(${this.railZoom});-moz-transform-origin:0 0`);
          }
          this.tapPrevDiff = curDiff;
        } else {
          if (!isDown || this.craneMoving) return;

          const x = e.pageX - railParkCntr.offsetLeft;
          const walk = x - startX;
          railParkCntr.scrollLeft = scrollLeft - walk;

          const y = e.pageY - railParkCntr.offsetTop;
          const walkY = y - startY;
          railParkCntr.scrollTop = scrollTop - walkY;
        }
      });

      const railParkCntr = document.querySelector("#railParkCntr");

      this.$utilities.dom.addHandler(railParkCntr, ["pointerdown"], (e) => {
        isDown = true;
        startX = e.pageX - railParkCntr.offsetLeft;
        startY = e.pageY - railParkCntr.offsetTop;
        scrollLeft = railParkCntr.scrollLeft;
        scrollTop = railParkCntr.scrollTop;
      });

      this.$utilities.dom.addHandler(railParkCntr, ["pointerleave"], () => {
        isDown = false;
      });

      this.$utilities.dom.addHandler(railParkCntr, ["pointerup"], () => {
        isDown = false;
      });
    },
    getMaxLengthRail() {
      const maxLengthRail = this._.maxBy(this.binari, (b) => {
        return b.lunghezza;
      });
      return maxLengthRail && maxLengthRail.lunghezza ? Math.min(maxLengthRail.lunghezza, 360) : 0;
    },
    async loadRailwayData() {
      try {
        if (!this.parkName) return;
        this.binari = (await this.$api.railData.getRailwayData(this.parkName)).reverse();
      } catch (e) {
        console.log(e);
      }
    },
    async loadCraneData() {
      try {
        this.listaGru = await this.$api.railData.getCraneData(this.parkName);
      } catch (e) {
        console.log(e);
      }
    },
    async loadRealTimeData() {
      console.time("railVisualizationRT_Time1");
      try {
        if (!this.$wsHubIsGroupRegistered("railVisualizationRT")) {
          await this.$wsHubRegister("railVisualizationRT", { parco: this.parkName }, (resConvogli) => this.setRealTimeData(resConvogli));
        }
        this.$wsHubOnDataChangedEvent("railVisualizationRT");
      } catch (e) {
        console.warn("railVisualizationRT error", e);
        let resConvogli = await this.$api.railData.getRailwayDataRT(this.parkName);
        this.setRealTimeData(resConvogli);

        if (this.timerRT) {
          clearInterval(this.timerRT);
        }
        this.timerRT = setInterval(async () => {
          try {
            console.log("in timeOut getRailwayDataRT");
            resConvogli = await this.$api.railData.getRailwayDataRT(this.parkName);
            this.setRealTimeData(resConvogli);
          } catch (e) {
            console.log(e);
          }
        }, 60000);
      }
      console.timeEnd("railVisualizationRT_Time1");
    },
    setRealTimeData(resConvogli) {
      this.resConvogli = resConvogli;
      this.resConvogli.forEach((c) => (this.wagonTotalCount += c.listCarri ? c.listCarri.length : 0));
      this.convogli = this._.transform(
        resConvogli,
        (result, convoglio) => {
          result[convoglio.binarioId] = _.filter(resConvogli, (c) => c.binarioId == convoglio.binarioId);
          return result;
        },
        {}
      );
    },
    onClickPlannedShow() {
      this.plannedShowing = !this.plannedShowing;
      if (this.plannedShowing) {
        this.featuresShowing = false;
      }
    },
    onClickFeaturesShow() {
      this.featuresShowing = !this.featuresShowing;
      if (this.featuresShowing) {
        this.plannedShowing = false;
      }
    },
    onClickYardShow() {
      this.yardShowing = !this.yardShowing;
    }
  },
  watch: {
    wagonSearchParam: async function (newValue) {
      const targa = newValue.trim();
      try {
        const convogli = this._.filter(this.resConvogli, (c) => {
          return this._.find(c.listCarri, (cc) => {
            const licensePlate = this.getCarroLicensePlate(cc);
            return targa && ((targa.length >= 3 && licensePlate.includes(targa.toLowerCase())) || licensePlate === targa.toLowerCase());
          });
        });

        if (!convogli.length && targa.length > 2) {
          const parcoRes = await this.$api.trainData.getParcoPerRicercaCarro(targa);
          if (!parcoRes || !parcoRes.parco) return;
          this.selectedPark = this._.find(this.parks, (p) => p.codice.toLowerCase() === parcoRes.parco.toLowerCase());
          this.showSuccess(`La ricerca per carro ha trovato corrispondenza sul parco ${this.selectedPark.codice}`);
        }
      } catch (e) {
        console.log(e);
      }
    },
    parkName: async function () {
      await this.$wsHubUnRegister("railVisualizationRT");
      await this.fetchData();
    },
    convogli: {
      handler: function (newConvogliVal) {
        this.railsWagonsMap = this._.transform(
          newConvogliVal,
          (result, convogli, idBinario) => {
            convogli.forEach((convoglio) => {
              if (convoglio.listCarri && convoglio.listCarri.length > 0) {
                result[idBinario] = convoglio.listCarri;
              }
            });
            return result;
          },
          {}
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.rail-park-cntr {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.no-radius-left {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.no-radius-right {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
